<template>
  <div>
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
  </div>
</template>

<script>
  import cabinetUtil from "@utils/cabinetUtil.js"
  export default {
    data() {
      let _this = this
      return {
        opt: {
          title: '用户列表',
          search: [{
              key: "memberName",
              label: "用户账号",
              maxlength: 10,
            },
            {
              key: "merchantId",
              label: "商家名称",
              type: 'remoteSearchById',
              opt: {
                list: [],
              }
            },
            {
              key: "mobile",
              label: "手机号码",
              maxlength: 15,
            }, {
              key: "memberType",
              label: "用户类型",
              type: "select-no-filterable",
              opt: {
                list: [{
                    label: "白名单",
                    value: "1"
                  },
                  {
                    label: "灰名单",
                    value: "2"
                  }
                ]
              }
            }
          ],
          columns: [{
              label: "头像",
              key: "avatarUrl",
              type: "img-shop"
            }, {
              label: "用户id",
              key: "userId"
            },
            {
              label: "用户账号",
              key: "memberName"
            },
            {
              label: "商家名称",
              key: "merchantName",
            },
            {
              label: "手机号码",
              key: "mobile"
            },
            {
              label: "用户类型",
              key: "memberTypeStr"
            },
            {
              label: "电池租借",
              key: "batteryRental",
              type: "switch1",
              opt: {
                activeText: "是",
                inactiveText: "否",
                //是 可编辑成否，否 不可编辑成是
                change(form, cb) {
                  if (form.batteryRental) {
                    const h = _this.$createElement;
                    _this.$msgbox({
                      title: "确定提示",
                      message: h("p", null, [
                        h(
                          "span", {
                            style: "color: #333;font-size: 16px;margin-left:98px"
                          },
                          "是否确定修改用户的租借状态？"
                        ),
                      ]),
                      showCancelButton: true,
                      confirmButtonText: "确定",
                      cancelButtonText: "取消"
                    }).then(action => {
                      return _this.post("mall-service/bc/update/member/info", {
                        userId: form.userId,
                      }, {
                        isUseResponse: true
                      }).then(res => {
                        if (res.data.code == 0) {
                          cb({
                            data: form,
                            result: true,
                          });
                          _this.$refs.myList.onRefresh();
                        }
                      }).catch(err => {
                        cb({
                          result: false,
                          msg: err.data.msg
                        });
                      });
                    })
                  } else {
                    _this.$message({
                      message: '当前状态不可编辑！',
                      type: 'warning'
                    })
                  }

                }
              }
            },
            {
              label: "服务到期时间",
              key: "bcEndDate"
            },
            {
              label: "租电池时间",
              key: "rentTime"
            },
            {
              label: "操作",
              key: "action",
              type: "action-but",
              opt: {
                list: [{
                  label: "详情",
                  on(row) {
                    _this.onDetailClick(row)
                  }
                }]
              }
            },
          ]
        }
      }
    },

    async activated() {
      try {
        this.merchantFlag = this.getLoginType('loginType');
        if (this.merchantFlag) {
          this.opt.search.forEach((item, index) => {
            if (item.key == 'merchantId') {
              this.opt.search.splice(index, 1);
            }
          })
          this.opt.columns.forEach((item, index) => {
            if (item.key == 'merchantName') {
              this.opt.columns.splice(index, 1);
            }
          })
        } else {
          let merchantList = await cabinetUtil.getMerchantList();
          this.opt.search.forEach((item, index) => {
            if (item.key == 'merchantId') {
              item.opt.list = merchantList;
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    },

    methods: {
      onGet(opt) {
        let searchForm = opt.searchForm
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          memberName: searchForm.memberName,
          mobile: searchForm.mobile,
          memberType: searchForm.memberType,
          ...opt.searchForm,
        }
        this.post('mall-service/bc/member/page', dto, {
          isUseResponse: true,
        }).then(res => {
          let obj = {
            data: [],
            total: 0,
          }
          obj.data = res.data.data.records;
          obj.total = res.data.data.total;
          if (obj.data) {
            obj.data.forEach(item => {
              item.memberName = item.memberName || '--';
              item.mobile = item.mobile || '--';
              item.memberTypeStr = ['普通会员', '白名单', '灰名单', '灰名单'][item.memberType];
              item.batteryRental = item.memberType == 1 || item.memberType == 2 ? true : false;
              item.bcEndDate = item.bcEndDate ? moment(item.bcEndDate).format('YYYY-MM-DD'): '--';
              item.rentTime = item.rentTime || '--';
              item.merchantName = item.merchantName || '--';

            })
          }
          opt.cb(obj)
        })
      },
      onDetailClick(item) {
        this.$router.push({
          path: '/main/tram/user/detail',
          query: {
            userId: item.userId,
            id: item.id,
          }
        })
        this.cache.set('tramMmemberDetailTabIndex', 0)
      },
      warning(val) {
        return new Promise((resolve, reject) => {
          resolve('当前状态下不可编辑！')
        })
      },

    }
  }
</script>

<style>

</style>